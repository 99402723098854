exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-genres-js": () => import("./../../../src/pages/genres.js" /* webpackChunkName: "component---src-pages-genres-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-movies-js": () => import("./../../../src/pages/movies.js" /* webpackChunkName: "component---src-pages-movies-js" */),
  "component---src-templates-genre-js": () => import("./../../../src/templates/Genre.js" /* webpackChunkName: "component---src-templates-genre-js" */),
  "component---src-templates-movie-js": () => import("./../../../src/templates/Movie.js" /* webpackChunkName: "component---src-templates-movie-js" */)
}

