import React from 'react'

import * as styles from './style.module.css'



const Logo = ({ width = 180, height = 80, color = 'red' }) => {

	const iconClasses = [
		styles.icon,
	]

	if( 'red' === color ) {
		iconClasses.push( styles.iconRed )
	} else if ( 'white' === color ) {
		iconClasses.push( styles.iconWhite )
	}

	return (
		<div
			className={styles.container}
			style={{
				'--width': `${width}px`,
				'--height': `${height}px`,
			}}
		>
			<svg className={iconClasses.join( ' ' )} width="439" height="123" viewBox="0 0 439 123" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M393.933 61.0659L373 1.8999H393.068L405.87 40.9979H406.216L419.364 1.8999H437.356L416.423 61.0659L438.171 122.5L418.171 121L404.486 80.7879H404.14L390.671 119L373.67 118L393.933 61.0659Z" />
				<path d="M341.049 1.8999H360.079L359.5 116.5L341 115.5L341.049 1.8999Z" />
				<path d="M278 1.8999H297.03V95.6999L328.343 97.5V114.8L278 113.5V1.8999Z" />
				<path d="M214.312 1.8999H264.655V19.1999H233.342V52.9349H257.908V70.2349H233.342V113H214.312V1.8999Z" />
				<path d="M141 1.8999H164.874L183.385 74.3869H183.731V1.8999H200.685L198.167 113L178.667 113.5L158.3 34.5969H157.954V114L141 114.5V1.8999Z" />
				<path d="M86.009 71.4459L63 1.8999H83.241L96.216 46.3609H96.562L109.537 1.8999H128.048L105.039 71.4459V116L86.009 117V71.4459Z" />
				<path d="M27.853 121.818C18.9448 122.642 11.8217 122.135 7.093 116.945C2.36433 111.64 0 104.085 0 94.2819V87.3619H17.992V95.6659C17.992 103.509 21.279 107.43 27.853 107.43C31.0823 107.43 33.5043 106.507 35.119 104.662C36.849 102.701 37.714 99.5873 37.714 95.3199C37.714 90.2452 36.5607 85.8049 34.254 81.9989C31.9473 78.0776 27.68 73.4066 21.452 67.9859C13.6093 61.0659 8.131 54.8379 5.017 49.3019C1.903 43.6506 0.346 37.3073 0.346 30.2719C0.346 20.6993 2.768 13.3179 7.612 8.12792C12.456 2.82259 19.4913 0.169922 28.718 0.169922C37.8293 0.169922 44.6917 2.82259 49.305 8.12792C54.0337 13.3179 56.398 20.8146 56.398 30.6179V35.6349H38.406V29.4069C38.406 25.2549 37.5987 22.2563 35.984 20.4109C34.3693 18.4503 32.005 17.4699 28.891 17.4699C22.5477 17.4699 19.376 21.3336 19.376 29.0609C19.376 33.4436 20.5293 37.5379 22.836 41.3439C25.258 45.1499 29.583 49.7633 35.811 55.1839C43.769 62.1039 49.2473 68.3896 52.246 74.0409C55.2447 79.6923 56.744 86.3239 56.744 93.9359C56.744 103.855 54.2643 111.467 49.305 116.772C44.1938 121 36.6938 121 27.853 121.818Z" />
			</svg>
		</div>
	)
}


export default Logo
